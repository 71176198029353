.App {
  padding-top: 10px;
  text-align: center;
  /* align-items: center; */
  justify-content: space-evenly;
  /* background-color: rgb(41, 42, 42); */
  /* background-color: rgb(83, 153, 153); */
  padding-bottom: 0.3rem;
}

